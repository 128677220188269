/**
FORM - COLLECTION
 */
.collection-item {
    display: flex;
}
.collection-item-remove {
    width: 30px;
}
.collection-item-content {
    width: 100%;
}