/*
FORM - IMAGE CUSTOM INPUT
 */
.fichier-preview-container {
    height: 76.5px;
    border: 1px solid #CCC;
    margin-left: 5px;
    border-radius: 10px;
}
.fichier-preview-image {
    height: 76.5px;
}
.fichier-preview-icone {
    height: 76.5px;
    width: 76.5px;
    text-align: center;
    font-size: 2em;
    padding-top: 0.6em;
}
.fichier-delete-btn {
    height: 25px;
    border-bottom-left-radius: 5px;
    width: 30px;
    font-size: 0.8em;
    background-color: rgba(255, 2555, 255, 0.8);
    padding: 3px 0 0 0;
    text-align: center;
    color: #333;
    cursor: pointer;
    position: absolute;
    right: 1em;
}
.fichier-delete-btn.is-delete {
    color: red;
}
/**
FORM - FICHIER CUSTOM INPUT
 */
.collection-fichier-item {
    border: 1px solid #BFBFBF;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0;
}
.fichier-custom-input-flex {
    display: flex;
    flex-wrap: wrap;
}
.fichier-custom-input-widget-container {
    flex: 1;
}
.fichier-custom-input-preview-fichier {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.5em;
    overflow: hidden;
    text-align: center;
}
.fichier-custom-input-preview-fichier .icon-input {
    font-size: 2em;
    padding: 0.5em;
}
.fichier-custom-input-preview-fichier img {
    width: 70px;
    height: 70px;
}
.fichier-custom-input-delete {
    position: absolute;
    padding: 8px;
    text-align: center;
    /*border-right: 1px solid #ced4da;*/
    /*border-bottom: 1px solid #ced4da;*/
    font-size: 0.75em;
    background-color: #e9ecef;
    border-bottom-right-radius: 0.2rem;
    cursor: pointer;
}
/**
FORM - IMAGE CUSTOM INPUT
 */
.image-custom-input-flex {
    display: flex;
    flex-wrap: wrap;
}
.image-custom-input-widget-container {
    flex: 1;
}
.image-custom-input-preview-image {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.5em;
    overflow: hidden;
}
.image-custom-input-preview-image img {
    width: 70px;
    height: 70px;
}
.image-custom-input-delete {
    position: absolute;
    padding: 8px;
    text-align: center;
    font-size: 0.75em;
    background-color: #e9ecef;
    border-bottom-right-radius: 0.2rem;
    cursor: pointer;
}
/**
CUSTOM FILE BOOTSTRAP
 */
.custom-file {
    overflow: hidden;
}